const Footer = () => {
	return (
		<div className=' text-white footer w-[100%] mt-8 bg-[#181818] bg-opacity-30'>
			<div className='ml-[5%] flex flex-col gap-4'>
				<h1 className=''>Контакты</h1>
				<div className='flex flex-col'>
					<a
						href='tel:+7 495 545-48-98'
						className=' ease-in hover:text-[#7013CD] duration-300'
					>
						+7 495 545-48-98
					</a>
					<a
						href='mailto:info@neiroseti.ai '
						target='_blank'
						className=' ease-in hover:text-[#7013CD] duration-300'
					>
						neiroseti@mail.ru
					</a>
					<a
						href='https://yandex.ru/maps/-/CDbzjL3p'
						target='_blank'
						className=' ease-in hover:text-[#7013CD] duration-300'
					>
						Ул. Грузинский Вал, 26с3
					</a>
				</div>
			</div>
			<div className='flex justify-around py-12'>
				<a href='https://t.me/gptneiroseti' target='_blank'>
					<img src='/img/tg.png' width={30} height={30} alt='' className='' />
				</a>
				<a href='https://vk.com/aoneiroseti' target='_blank'>
					<img src='/img/vk.png' width={30} height={30} alt='' className='' />
				</a>
				<a href='mailto:info@neiroseti.ai' target='_blank'>
					<img src='/img/mail.png' width={30} height={30} alt='' className='' />
				</a>
			</div>
		</div>
	)
}

export default Footer
