import React, {useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import Stepper from '../components/Stepper'
import 'tailwindcss/tailwind.css'
import HeaderInForm from '../components/HeaderInForm'
import axios from "axios";

const formJson = {
    type: 'large_client',
    steps: [
        {
            step_title: 'Основная информация о компании',
            fields: [
                {
                    id: 1,
                    variable_name: 'Info',
                    label: 'Ваши пожелания по внедрению AI',
                    example:
                        'Введите название вашей компании, планируемое время начала внедрения ИИ, а также сроки выполнения проекта под ключ"',
                    type: 'text',
                },
            ],
        },
        {
            step_title: 'Бюджет и методы',
            fields: [
                {
                    id: 4,
                    variable_name: 'initial_budget',
                    label: 'Объем средств для начального этапа',
                    example: '1 000 000 руб.',
                    type: 'number',
                },
                {
                    id: 5,
                    variable_name: 'implementation_method',
                    label: 'Способ внедрения',
                    example: 'Услуга "под ключ"',
                    type: 'text',
                },
            ],
        },
        {
            step_title: 'Дополнительные сведения',
            fields: [
                {
                    id: 7,
                    variable_name: 'other_ai_plans',
                    label: 'Планы по внедрению других ИИ-решений',
                    example: 'Да, чат-боты',
                    type: 'text',
                },
                {
                    id: 8,
                    variable_name: 'messenger',
                    label: 'Используемый корпоративный мессенджер',
                    example: 'VK Teams',
                    type: 'text',
                },
                {
                    id: 9,
                    variable_name: 'bitrix_usage',
                    label: 'Наличие использования Битрикс',
                    example: 'Нет',
                    type: 'boolean',
                },
            ],
        },
    ],
}

const Form = ({chat_id, user_id}) => {
    const {stepId} = useParams()
    const navigate = useNavigate()
    const [currentStep, setCurrentStep] = useState(Number(stepId) || 1)
    const [formData, setFormData] = useState({})

    useEffect(() => {
        if (stepId) {
            setCurrentStep(Number(stepId))
        }
    }, [stepId])

    const handleChange = e => {
        const {name, value, type, checked} = e.target
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        })
    }

    const nextStep = () => {
        const nextStep = Math.min(currentStep + 1, formJson.steps.length)
        setCurrentStep(nextStep)
        navigate(`/form/${nextStep}`)
    }

    const prevStep = () => {
        const prevStep = Math.max(currentStep - 1, 1)
        setCurrentStep(prevStep)
        navigate(`/form/${prevStep}`)
    }

    const handleSubmit = async e => {
        e.preventDefault()
        try {
            const response = await axios.post('https://apibot.2024year.ru/application', {
                ...formData, chat_id, user_id
            });

            if (response.status === 200) {
                navigate('/success');
            } else {
                console.error('Error submitting form:', response.statusText);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    }

    const renderField = field => {
        const isFullHeight = field.id === 1 ? 'h-[220px]' : ''
        switch (field.type) {
            case 'text':
                return (
                    <label key={field.id} className='flex flex-col gap-2 block mb-8'>
						<span className='text-white placeholder-whitespace-pre-wrap'>
							{field.label}:
						</span>
                        <textarea
                            type='text'
                            name={field.variable_name}
                            value={formData[field.variable_name] || ''}
                            onChange={handleChange}
                            placeholder={field.example}
                            className={`mt-1 block w-full px-3 py-2 bg-gray-800 text-white border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500 ${isFullHeight}`}
                        ></textarea>
                    </label>
                )
            case 'number':
                return (
                    <label key={field.id} className='flex flex-col gap-2 block mb-4'>
                        <span className='text-white'>{field.label}:</span>
                        <input
                            type='number'
                            name={field.variable_name}
                            value={formData[field.variable_name] || ''}
                            onChange={handleChange}
                            placeholder={field.example}
                            className='mt-1 block w-full px-3 py-2 bg-gray-800 text-white border border-gray-600 rounded-md shadow-sm focus:outline-none focus:ring-purple-500 focus:border-purple-500'
                        />
                    </label>
                )
            case 'boolean':
                return (
                    <label
                        key={field.id}
                        className='flex flex-col gap-2 items-center mb-4'
                    >
                        <span className='text-white mr-2'>{field.label}:</span>
                        <input
                            type='checkbox'
                            name={field.variable_name}
                            checked={formData[field.variable_name] || false}
                            onChange={handleChange}
                            className='h-5 w-5 text-purple-600'
                        />
                    </label>
                )
            default:
                return null
        }
    }

    return (
        <>
            <HeaderInForm/>

            <div className='min-h-screen text-white flex flex-col items-center'>
                <Stepper steps={formJson.steps} currentStep={currentStep - 1}/>
                <div className='flex-grow w-full max-w-lg p-4'>
                    <form onSubmit={handleSubmit} className='w-full'>
                        {formJson.steps.map(
                            (step, index) =>
                                currentStep === index + 1 && (
                                    <div key={index}>
                                        <h2 className='text-2xl mb-6'>{step.step_title}</h2>
                                        {step.fields.map(field => renderField(field))}
                                    </div>
                                )
                        )}
                        <div className='flex justify-between fixed bottom-0 left-0 right-0 p-4 '>
                            {currentStep < formJson.steps.length && (
                                <div className='flex justify-center flex-col w-[100%] gap-4'>
                                    <button
                                        type='button'
                                        onClick={nextStep}
                                        className='py-[10px] text-xl bg-purple-600 rounded-lg'
                                    >
                                        Ответить
                                    </button>
                                    <button className=' py-[10px] flex justify-center rounded-lg bg-[#4C4C4C]'>
                                        <img
                                            src='/img/micro.svg'
                                            width={32}
                                            height={32}
                                            alt='micro'
                                        />
                                    </button>
                                </div>
                            )}

                            {currentStep === formJson.steps.length && (
                                <div className='flex justify-center flex-col w-[100%] gap-4'>
                                    <button
                                        type='submit'
                                        className='bg-purple-600 flex justify-center items-center text-xl  w-[100%] py-[10px] rounded-md'
                                    >
                                        Отправить
                                    </button>
                                    <button className=' py-[10px] flex justify-center rounded-lg bg-[#4C4C4C]'>
                                        <img
                                            src='/img/micro.svg'
                                            width={32}
                                            height={32}
                                            alt='micro'
                                        />
                                    </button>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Form
