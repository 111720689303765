import React from 'react'

const Stepper = ({ steps, currentStep }) => {
	return (
		<div className='sticky top-0  p-4 z-50 mb-2'>
			<div className='flex justify-center'>
				{steps.map((step, index) => (
					<div key={index} className='flex items-center'>
						<div
							className={`w-8 h-8 rounded-full flex items-center justify-center text-sm ${
								index < currentStep
									? 'bg-green-500 text-white'
									: index === currentStep
									? 'bg-green-500 text-white'
									: 'bg-gray-700 text-gray-400'
							}`}
						>
							{index + 1}
						</div>
						{index < steps.length - 1 && (
							<div className='w-10 h-1 bg-gray-700 mx-2'></div>
						)}
					</div>
				))}
			</div>
		</div>
	)
}

export default Stepper
