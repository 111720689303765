import '../App.css'
const products = [
	{
		id: 1,
		name: 'Видеоконференцсвязь для проведения вебинаров и онлайн-встреч c аналитикой реакции участников в режиме реального времени.',
		link: 'https://index-5.ru/',
	},
	{
		id: 2,
		name: 'Интерактивный отчет для отслеживания динамики когнитивных показателей',
		link: 'https://player.aoneiro.com/289d858f-d1ec-465f-b6a8-1eaf40226505',
	},
	{
		id: 3,
		name: 'Система контроля безопасности на рабочих местах.',
		link: 'https://neiroseti.ai/products',
	},
	{
		id: 4,
		name: 'Умная рекламная панель с аналитикой маркетинговой активности.',
		link: 'https://neiroseti.ai/products',
	},
	{
		id: 5,
		name: 'NFC ID - приложение для записи и чтения NFC-меток.',
		link: 'https://apps.apple.com/ru/app/nfc-id/id1660349725',
	},
	{
		id: 6,
		name: 'Умный детский планшет с функциями родительского контроля.',
		link: 'https://badikids.ru/',
	},
	{
		id: 7,
		name: 'Корпоративный планшет для ритейла и MLM компаний.',
		link: 'https://neiroseti.ai/products',
	},
	{
		id: 8,
		name: 'Роботизированный отдел продаж для салонов красоты.',
		link: 'https://neiroseti.ai/products',
	},
	{
		id: 9,
		name: 'Сервис генерации видеоконтента с цифровым двойником.',
		link: 'https://neiroseti.ai/products',
	},
]
const ProductsList = () => {
	return (
		<div className=' '>
			<h1 className='ml-[5%] my-12 text-3xl text-white'>Наши продукты</h1>
			<div className='flex flex-col justify-center items-center gap-12 '>
				{products.map(product => (
					<div className='flex flex-col gap-4 items-center'>
						<h2
							className='text-white max-h-[300px] max-w-[350px]  text-justify'
							key={product.id}
						>
							{product.name}
						</h2>
						<a
							className='text-white duration-300 ease-in py-2 px-6 bg-[#7013CD] rounded-full bg-opacity-70 hover:bg-[#101010] btn-look'
							href={product.link}
							target='_blank'
						>
							Посмотреть
						</a>
					</div>
				))}
			</div>
		</div>
	)
}

export default ProductsList
