import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Main from './page/Main'
import Form from './page/Form'
import { Route, Routes } from 'react-router-dom'
import Success from './page/Success'

const App = ({ chat_id, user_id }) => {
	return (
		<>
			<Routes>
				<Route path='/' element={<Main />} />
				<Route path='/form/:stepId' element={<Form chat_id={chat_id} user_id={user_id} />} />
				<Route path='/success' element={<Success />} />
			</Routes>
		</>
	)
}

export default App
