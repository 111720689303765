import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../App.css'
const Carousel = () => {
	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 7000,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 0,
		cssEase: 'linear',
	}

	const logos = [
		'/img/Carousel_logo-1.png',
		'/img/Carousel_logo-2.png',
		'/img/Carousel_logo-3.png',
		'/img/Carousel_logo-4.png',
		'/img/Carousel_logo-5.png',
		'/img/Carousel_logo-6.png',
		'/img/Carousel_logo-7.png',
		'/img/Carousel_logo-8.png',
		'/img/Carousel_logo-9.png',
	]

	return (
		<div className='carousel-container '>
			<Slider className=' flex items-center ' {...settings}>
				{logos.map((logo, index) => (
					<div className=' flex items-center' key={index}>
						<img
							src={logo}
							alt={`logo-${index}`}
							style={{ maxWidth: '100px' }}
						/>
					</div>
				))}
			</Slider>
		</div>
	)
}

export default Carousel
