const Bunner = () => {
	return (
		<div className='mb-12'>
			<h1 className='ml-4 text-3xl max-h-[200px] max-w-[320px] text-white'>
				Ваши AI решения на расстоянии одного клика!
			</h1>
			<h3 className='text-white text-xs absolute left-[30%] top-48'>
				Одна экосистема
				<br /> для всех продуктов
			</h3>
		</div>
	)
}

export default Bunner
