import { Link } from 'react-router-dom'
import '../App.css'
const Header = () => {
	return (
		<header className='sticky top-0  z-50 mb-12'>
			<div className='h-[70px] bg-[#111011] header-flex flex justify-around items-center'>
				<Link to='/'>
					<img src='/img/Logo.svg' alt='' />
				</Link>

				<Link
					to='/form/1'
					className='btn-form text-white ease-in duration-300 bg-[#1F1F1F] py-2 px-6 rounded-full hover:bg-[#111011]'
					href='/'
				>
					Заполнить форму
				</Link>
			</div>
		</header>
	)
}

export default Header
