import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import {BrowserRouter} from 'react-router-dom'

const root = ReactDOM.createRoot(document.getElementById('root'))

const tg = window.Telegram.WebApp;

tg.ready();

const initDataUnsafe = tg.initDataUnsafe;
const chat_id = initDataUnsafe?.chat?.id || 0;
const user_id = initDataUnsafe?.user?.id || 0;

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App chat_id={chat_id} user_id={user_id}/>
        </BrowserRouter>
    </React.StrictMode>
)
