import { Link } from 'react-router-dom'
import '../App.css'
const HeaderInForm = () => {
	return (
		<header className=''>
			<div className='h-[70px] header-flex flex justify-around items-center'>
				<Link to='/'>
					<img src='/img/Logo.svg' alt='' />
				</Link>
			</div>
		</header>
	)
}

export default HeaderInForm
