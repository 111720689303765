import Bunner from '../components/Bunner'
import Carousel from '../components/Carousel'
import Footer from '../components/Footer'
import ProductsList from '../components/ProductsList'
import Header from '../components/Header'

const Main = () => {
	return (
		<>
			<Header />

			<Bunner />
			<Carousel />
			<ProductsList />
			<Footer />
		</>
	)
}

export default Main
