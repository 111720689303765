import HeaderInForm from '../components/HeaderInForm'

const Success = () => {
	const handleClose = () => {
		window.Telegram.WebApp.close();
	};

	return (
		<>
			<HeaderInForm />
			<div className='flex flex-col justify-center items-center mt-32 gap-4'>
				<img className='w-20 h-20' src='/img/Success.svg' alt='Success'/>
				<div className=' text-xl text-white'>
					Благодарим за вашу отзывчивость!
				</div>
				<button
					onClick={handleClose}
					className='mt-6 py-2 px-4 bg-purple-600 text-white rounded-lg'
				>
					Закрыть
				</button>
			</div>
		</>
	)
}

export default Success
